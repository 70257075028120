import { defineStore } from 'pinia'
import type { JobItem } from '~/models/Job'

interface JobState {
  selectedJob: JobItem
  deletedJobId: number
}

export const useDetail = defineStore('detail', {
  state: (): JobState => ({
    selectedJob: {} as JobItem,
    deletedJobId: null,
  }),
  actions: {
    setDeletedJobId(id: number) {
      this.deletedJobId = id
    },
    updateSelectedJob(data: JobItem) {
      this.selectedJob = data
    },
    resetSelectedJob() {
      this.selectedJob = {} as JobItem
    },
  },
  getters: {
    jobDetail() {
      return this.selectedJob
    },
  },
})
